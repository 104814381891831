function testWebP(callback) {

    var webP = new Image();
    webP.onload = webP.onerror = function () {
        callback(webP.height == 2);
    };
    webP.src = "data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA";
}

testWebP(function (support) {

    if (support == true) {
        document.querySelector('body').classList.add('webp');
    } else {
        document.querySelector('body').classList.add('no-webp');
    }
});

// мобильное меню --------------------------------------------->
function showModalWin() {
    var darkLayer = document.createElement('div');
    darkLayer.id = 'backdrop';
    document.getElementById('dl-backdrop').appendChild(darkLayer);
    darkLayer.onclick = function () {
        darkLayer.parentNode.removeChild(darkLayer);
        return false;
    };
};
$('header .navbar-toggler').click(function () {
    showModalWin();
    $('body').addClass('overflow-hidden');
});
$(document).on('click', function (e) {
    if (!$(e.target).closest("#navbarNav,.navbar-toggler").length) {
        $('#navbarNav.navbar-collapse').removeClass('show');
        $('.navbar-toggler').attr('aria-expanded', 'false');
        $('body').removeClass('overflow-hidden');
    }
    e.stopPropagation();
});

$('.slider').owlCarousel({
    items: 1,
    dots: false,
    loop: true,
    nav: false,
    navText: false,
    mouseDrag: true,
    touchDrag: true,
});

$('.slider-gallery').owlCarousel({
    items: 3,
    dots: false,
    loop: true,
    nav: true,
    navText: false,
    mouseDrag: true,
    touchDrag: true,
    responsiveClass: true,
    responsive: {
        0: {
            items: 1,
            margin: 10,
            nav: false,
        },
        576: {
            items: 2,
            margin: 10,
        },
        768: {
            items: 3,
            margin: 20,
        },
        1200: {
            items: 3,
            margin: 50,
        },
    }
});
